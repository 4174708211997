import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(LanguageDetector) // Add this line
  .use(initReactI18next) 
  .init({
    resources,
    lng: "de",
    fallbackLng: "en", // Use English as the fallback language
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], // order and from where user language should be detected
      caches: ['localStorage', 'cookie'] // where to store the detected language
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
