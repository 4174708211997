import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, Container, Stack, useMediaQuery, useTheme, ThemeProvider, createTheme } from '@mui/material';
import { Box } from '@mui/system';

const theme = createTheme();

function App() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // State variables
  const [lang, setLang] = useState(i18n.language || window.navigator.language); // Get language from browser
  const [rooms, setRooms] = useState([]); // List of all rooms
  const [timeframe, setTimeframe] = useState([]);
  const [campus, setCampus] = useState(''); // Selected campus
  const [building, setBuilding] = useState(''); // Selected building
  const [roomType, setRoomType] = useState(''); // Selected room type
  const currentTime = new Date();
  const [date, setDate] = useState(new Date(currentTime.getTime() - (currentTime.getTimezoneOffset() * 60000)).toISOString().split("T")[0]); // Selected date
  const [startTime, setStartTime] = useState(currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })); // Selected start time
  const [endTime, setEndTime] = useState(currentTime.getHours() >= 22 ? "23:59" : new Date(currentTime.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })); // Selected end time
  const [availableRooms, setAvailableRooms] = useState([]); // List of available rooms

  // useEffect to set language on init
  useEffect(() => {
    const browserLang = window.navigator.language.split('-')[0]; // Get the language part from language-COUNTRY
    i18n.changeLanguage(browserLang);
    setLang(browserLang);
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  // necessary optimization for large arrays
  const sortedArrayToBST = (arr) => {
    if (!arr.length) {
        return null;
    }

    var middle = Math.floor(arr.length / 2);

    return {
        data: arr[middle],
        left: sortedArrayToBST(arr.slice(0, middle)),
        right: sortedArrayToBST(arr.slice(middle + 1))
    };
  };

  useEffect(() => { document.title = t('htmlTitle') }, [lang]);

  // Effect to load room data
  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/rooms.json`)
      .then(res => {
        setRooms(res.data.rooms.map(room => ({
          ...room,
          events: sortedArrayToBST(room.events.map(event => [new Date(event[0]), new Date(event[1])]))
        })));
        setTimeframe(res.data.timeframe.map(date => new Date(date)));
      });
  }, []);

  // Effect to filter available rooms
  useEffect(() => {
    const event = [new Date(`${date}T${startTime}`), new Date(`${date}T${endTime}`)];
    const selectedDate = new Date(date);
    if (selectedDate >= timeframe[0] && selectedDate <= timeframe[1]) {
      const filteredRooms = rooms.filter(room => {
        return (campus === '' || room.campus === campus) &&
          (building === '' || room.building === building) &&
          (roomType === '' || room.room_type === roomType);
      });
      setAvailableRooms(getAvailableRooms(filteredRooms, event));
    } else {
      setAvailableRooms([]);
    }
  }, [rooms, campus, building, roomType, date, startTime, endTime, timeframe]);

  // Function to traverse the BST and check for conflicting events
  const isEventConflict = (root, event) => {
    if (root === null) return false;

    // Check conflict at current node
    if (event[0] < root.data[1] && event[1] > root.data[0]) return true;

    // we now know that the event is either before or after the current node

    // check if the event is before the current node
    if (event[1] <= root.data[0]) return isEventConflict(root.left, event);

    // otherwise, the event is after the current node
    else return isEventConflict(root.right, event);
  }

  // Function to get available rooms
  const getAvailableRooms = (rooms, event) => {
    return rooms.filter(room => {
      return !isEventConflict(room.events, event);
    });
  };

  // List of campuses, buildings, and room types
  const campuses = [...new Set(rooms.map(room => room.campus))].sort();
  const buildings = [...new Set(rooms.filter(room => room.campus === campus || campus === '').map(room => room.building))].sort();
  const roomTypes = [...new Set(rooms.filter(room => (room.building === building || building === '') && (room.campus === campus || campus === '')).map(room => room.room_type))].sort();

  return (
    <ThemeProvider theme={theme}>
      <Box className="App" p={3}>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              {t('title')}
            </Typography>
            <Box p={1} paddingTop="0px" paddingBottom="0px" paddingRight="0px">
              <Button color="inherit" onClick={() => changeLanguage('en')}>EN</Button>
              <Button color="inherit" onClick={() => changeLanguage('de')}>DE</Button>
            </Box>
          </Box>
          <Typography variant="body1" style={{margin: '16px 0', paddingBottom: '8px', textAlign: 'justify'}}>
            {t('description')}
          </Typography>

          {/* Date and Time Selection */}
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 2, my: 2, paddingLeft: '0px', paddingRight: '0px' }} p={1}>
            <TextField
              label={t('dateLabel')}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('startTimeLabel')}
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('endTimeLabel')}
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          {/* Campus, Building, and Room Type Filters */}
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 2, my: 2, paddingLeft: '0px', paddingRight: '0px' }} p={1}>
            <Select
              value={campus}
              displayEmpty
              onChange={(e) => { setCampus(e.target.value); setBuilding(''); setRoomType(''); }}
              style={{ flexGrow: 1 }}
            >
              <MenuItem value="">{t('allCampusesOption')}</MenuItem>
              {campuses.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            </Select>
            <Select
              value={building}
              displayEmpty
              onChange={(e) => { setBuilding(e.target.value); setRoomType(''); }}
              style={{ flexGrow: 1 }}
            >
              <MenuItem value="">{t('allBuildingsOption')}</MenuItem>
              {buildings.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            </Select>
            <Select
              value={roomType}
              displayEmpty
              onChange={(e) => setRoomType(e.target.value)}
              style={{ flexGrow: 1 }}
            >
              <MenuItem value="">{t('allRoomTypesOption')}</MenuItem>
              {roomTypes.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
            </Select>
          </Box>

          {/* Display available rooms */}
          <div>
            {
              // Check if timeframe is defined and date is within timeframe
              timeframe && timeframe.length === 2 && (new Date(date)) >= timeframe[0] && (new Date(date)) <= timeframe[1] ? (
                <Box p={1} paddingLeft="0px" paddingRight="0px">
                  <Typography variant="h6">{t('availableRoomsHeader')}</Typography>
                  <TableContainer 
                    component={Paper} 
                    sx={{ mt: 3, mb: 2, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px'}}
                  >
                    <Table aria-label="room table">
                      <TableHead>
                        {!isMediumScreen && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                          >
                            <TableCell sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                              <Typography variant="body1" sx={{fontWeight: '500'}}>{t('nameColumnHeader')}</Typography>
                            </TableCell>
                            <TableCell sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', borderLeft: '1px solid rgba(0, 0, 0, 0.12)'}}>
                              <Typography variant="body1" sx={{fontWeight: '500'}}>{t('buildingColumnHeader')}</Typography>
                            </TableCell>
                            <TableCell sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', borderLeft: '1px solid rgba(0, 0, 0, 0.12)'}}>
                              <Typography variant="body1" sx={{fontWeight: '500'}}>{t('campusColumnHeader')}</Typography>
                            </TableCell>
                            <TableCell sx={{ paddingLeft: '18px', paddingRight: '18px', borderLeft: '1px solid rgba(0, 0, 0, 0.12)'}}>
                              <Typography variant="body1" sx={{fontWeight: '500'}}>{t('roomTypeColumnHeader')}</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {availableRooms.map((room, index) => (
                          <TableRow
                            key={index}
                            sx={{ 'td, th': { borderBottom: 0, borderTop: '1px solid rgba(0, 0, 0, 0.12)' },
                                  '&:hover': { backgroundColor: theme.palette.action.hover },
                                  transition: 'all 0.2s ease',
                                  '&:first-child td': isMediumScreen ? { borderTop: 0 } : {} }}
                          >
                            {isMediumScreen ? (
                              <TableCell>
                                <a href={room.schedule_url} style={{ textDecoration: 'none', color: 'inherit' }}>
                                  <Stack spacing={1}>
                                    <Typography variant="subtitle1" color="primary">{room.name}</Typography>
                                    <Typography variant="body2" color="textSecondary">{t('buildingColumnHeader')}: {room.building}</Typography>
                                    <Typography variant="body2" color="textSecondary">{t('campusColumnHeader')}: {room.campus}</Typography>
                                    <Typography variant="body2" color="textSecondary">{t('roomTypeColumnHeader')}: {room.room_type}</Typography>
                                  </Stack>
                                </a>
                              </TableCell>
                            ) : (
                              <>
                                <TableCell 
                                  component="th" 
                                  scope="row"
                                  sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                >
                                  <a href={room.schedule_url} style={{ textDecoration: 'none', color: 'inherit' }}>
                                      <Typography variant="subtitle1" color="primary">{room.name}</Typography>
                                  </a>
                                </TableCell>
                                <TableCell 
                                  sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
                                >
                                  <Typography variant="body2" color="textSecondary">{room.building}</Typography>
                                </TableCell>
                                <TableCell 
                                  sx={{ paddingLeft: '18px', paddingRight: '18px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
                                >
                                  <Typography variant="body2" color="textSecondary">{room.campus}</Typography>
                                </TableCell>
                                <TableCell 
                                  sx={{ paddingLeft: '18px', paddingRight: '18px', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
                                >
                                  <Typography variant="body2" color="textSecondary">{room.room_type}</Typography>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : timeframe && timeframe.length === 2 ? (
                <Typography variant="body1" color="#bb0000" textAlign="justify">
                  {t('outsideTimeframeMessage')} (
                  {timeframe[0].toISOString().split("T")[0]} &ndash; {timeframe[1].toISOString().split("T")[0]})
                </Typography>
              ) : (
                <Typography variant="body1" align="center" paddingTop="32px" fontWeight="500">
                  {t('loadingMessage')}
                </Typography>
              )
            }
          </div>

          <Typography variant="body1" style={{margin: '16px 0', paddingTop: '24px', textAlign: 'center'}}>
            {t('creatorMessage')}
          </Typography>

          <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
            <Link href={`${process.env.PUBLIC_URL}/impressum/`} target="_blank" rel="noopener noreferrer">
              {t('impressum')}
            </Link>
            {' | '}
            <Link href={`${process.env.PUBLIC_URL}/privacy/`} target="_blank" rel="noopener noreferrer">
              {t('privacyPolicy')}
            </Link>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
